.sub-title {

    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
}

.genre-selector {
    padding-left: 10%;
    padding-right: 10%;
}

.save-button {
    color: #bdbdbd !important;
    border-color: #bdbdbd !important;
  }

.metrics-panel {
  width: 100%;
}
