.App {
    text-align: center;
    display: flex;
    height: 100%;
    width: 100%;
}

.App-header {
    background: #0d1117;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-top: 1em;
}

.settings-panel {
    position: sticky;
    position: -webkit-sticky;
    top: 1em;
}

.display-panel {
    width: 100%;
    background-color: #161b22;
    color: white;
}

.welcome-text {
    width: 100%;
    height: 100%;
}

.more-genres-modal {
}