.slider-wrapper {
    text-align: center;
}

.slider-title {
    box-sizing: border-box;
    text-align: end;
    width: 50%;
    padding-top: 0.275em;
}

.slider-group {
    display: inline-flex;
    width: 100%;
}

.slider-control {
    box-sizing: border-box;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}

