.text-input {
    background-color: transparent;
    color: white;
    border: none;
    font-size: 2em;
}

.text-input:focus {
    outline: none;
    color: #52af77;
    border-color: #52af77;
    border-bottom: 1px solid;
}

.text-input:hover {
    color: #52af77;
    border-color: #52af77;
    border-bottom: 1px solid;
    width: '50%';  
}

.text-input:hover::placeholder {
    color: #52af77;
}

.text-input:focus::placeholder {
    color: #52af77;
}
