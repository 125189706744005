.display-header {
    position: sticky;
    top: 0em;
    z-index: 100;
    color: white;
    background-color: #0d1117;
    width: 100%;
    height: 8em;
    box-shadow: 0 8px 6px -6px black;
    text-align: left;
}

.sm-display-header {
    position: sticky;
    top: 0em;
    z-index: 100;
    color: white;
    background-color: #0d1117;
    width: 100%;
    height: 4em;
    box-shadow: 0 8px 6px -6px black;
    text-align: left;
  }

.user-image {
    object-fit: contain;
    height: 100%;
    float: left;
  
}

.user-data {
    width: 100%;
    height: 50%;
}

.hello-text {
    height: 100%;
    margin-left: 9em;
    padding-top: 0.75em;
}

.create-playlist-text {
    height: 100%;
    margin-left: 9em;
  }

.sm-create-playlist-text {
    height: 100%;
    margin-left: 5em;
    padding-top: 0.875em;
}
  
.save-button-container {
    float: right;
    margin-right: 2em;
}
  
.save-button {
    color: #bdbdbd !important;
    border-color: #bdbdbd !important;
}
  
.loading-save {
    margin-right: 2.75em;
}
  
.loading-playlist {
    width: 100%;
    margin-top: 5em;
}

.instruction-text {
    margin-top: 15em;
    color: #8f8e8e;
}
  
.no-found-text {
    margin-top: 15em;
    color: #8f8e8e;
}
  
  